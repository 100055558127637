:root{
  --primary: #ff9999;
  --lightneutral: white;
  --darkneutral: black;
  --secondary: #ffcdcd;
}

@font-face {
  font-family: 'Futura Std Medium';
  src: url('./fonts/Futura Std Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Horizon Regular';
  src: url('./fonts/Horizon\ Regular.otf') format('opentype');
}
@font-face{
  font-family: 'Fascinate Regular';
  src: url('./fonts/Fascinate\ Regular.ttf') format('truetype');
}
@font-face{
  font-family: 'Delm';
  src: url('./fonts/Delm.ttf') format('truetype');
}
@font-face {
  font-family: 'Chewy Pro';
  src: url('./fonts/ChewyPro.otf') format('opentype');
}
@font-face {
  font-family: 'Verona';
  color: #ff4400;
  src: url('./fonts/Verona-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Vanguard';
  src: url('./fonts/VanguardCF.otf') format('opentype');
}
@font-face {
  font-family: 'PlayFairDisplay';
  src: url('./fonts/PlayfairDisplay-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Gladiola';
  src: url('./fonts/Gladiola.ttf') format('truetype');
}
.MuiPaper-rounded::before,
.MuiAccordion-root::before,
.MuiAccordion-rounded::before,
.MuiPaper-root.MuiPaper-big-container.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1wy8ge4::before,
.MuiAccordion-gutters.css-1g6xv4p-MuiPaper-root-MuiAccordion-root::before, 
.MuiPaper-root::before, .MuiPaper-loginfo::before, .MuiPaper-big-container::before,
.MuiPaper-root.MuiPaper-loginfo.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-mgboyq::before,
.MuiPaper-root.MuiPaper-big-container.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1rkyvf9::before,
.MuiPaper-root.MuiPaper-big-container.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1ip3wk-MuiPaper-root-MuiAccordion-root::before,
.MuiPaper-root.MuiPaper-loginfo.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-d7tjfc-MuiPaper-root-MuiAccordion-root::before{
  display: none;;
}

.scroll {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #c47925 #f1f1f1;
}
.scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.scroll::-webkit-scrollbar-thumb:hover {
   background-color: #555;
}

.styled-select {
  width: 200px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #333;
  border-radius: 8px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
}

.styled-select:hover {
  border-color: #555;
}

.styled-select:focus {
  outline: none;
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.datepickerW{
  display: flex;
  gap: 40px;
  width: 290px;
  font-size: 20px;
  cursor: pointer;
  background-color: #f993; 
  height: 50px; 
  padding: 0 15px 0 15px; 
  box-sizing: border-box; 
  border: 1px solid #cabbbb;
}
.datepicker{
  width: 100%;
  font-size: 13px;
  cursor: pointer;
}
.react-datepicker__calendar-icon {
  top: 8px;
}
input.datepicker{ border-radius: 5px;}
input.datepicker::placeholder{color: rgb(173, 173, 173);}
input.datepicker-2{ border: 1px solid rgb(173, 173, 173); }
input.datepicker-3{ border: none; }
input.datepicker-2:hover{border: 1px solid black; }
input.datepicker:focus{ outline: none; }
.datepicker-2{ background-color: #ece3ff; height: 48px; width: 70px; padding: 0 15px;}
.datepicker-3{ height: 43px; padding: 0 15px; box-sizing: border-box; background-color: transparent;}
.react-datepicker__year-dropdown .react-datepicker__year-option{
  height: 30px;
}
.datepicker-4{ background-color: #f993; height: 50px; padding: 0 15px; box-sizing: border-box; border: 1px solid #cabbbb}
.datepicker-4:hover{ border: 1px solid black; }
.datepicker-5{ background-color: #e3e8fe; height: 50px; padding: 0 15px; box-sizing: border-box; border: 1px solid #cabbbb}

.link1{ color: black; transition: color .5s;}
.link1:hover{ color: var(--primary); }

.usericon img{ transition: .5s; }
.usericon:hover img:not(.bell){
  transform: scale(1.05) ;
}

.sliding-text {
  --width: 0;
  --anim-duration: 7s;
  animation: slide-text var(--anim-duration) ease-in-out infinite;
}
@keyframes scroll {
  0% {transform: translateX(100%)}
  10%, 90%{opacity:1}
  100% {transform: translateX(-100%);opacity: 0;}
}
@keyframes slide-text{
  0%, 5%{ left: 0;}
  45%, 55%{ left: calc(100% - var(--width));}
  95%, 100%{ left: 0;}
}

.wrap-content{
  display: flex;
  flex-direction: row;
}
.cond-rendering{ display: flex;}
.cond-rendering-inv{ display: none;}
.cond-rendering-inv .padding-change, .cond-rendering .padding-change{ padding: 10px 30px; }
.width-resize{ width: 50%; }
.head { height: 70px; }
.none { display: flex; }
.logo { height: 75%; }
.supervisor-card{ width: 200px; height: 300px; }
.admin-card{ width: 250px; height: 350px; }
.height-resize-sup{height: 60px;}
.height-resize-adm{ height: 100px; padding: 0 175px; }
.band img {height: 80px }
.profilelight {
  width: 100px; 
  height: 100px;
  border-radius: 100px;
}
.profileheader {
  width: 45px;
  height: 50px;
  border-radius: 30px
}

.profilebox {
  width: 70px;
  height: 70px;
  border-radius: 70px;
}
.gap-resize{ gap: 8px; }
.imgbg{ width: 600px}
.profil-display { display: none ;}
.scroll2 {scrollbar-color: #f1f1f1 #d48b70}
.profil-display-inv { display: flex;}

@media (max-width: 1100px){
  .gap-resize{ gap: 5px; }
}
@media (max-width: 900px) {
  .width-resize{ width: 70%; }
  .cond-rendering{ display:none;}
  .cond-rendering-inv{ display:flex;}
  .imgbg{ width: 450px}
  .height-resize-adm{ height: 80px; padding: 0 150px; }
}
@media (max-width: 700px) {
  .wrap-content {
    flex-direction: column;
    align-items: center;
  }
  .wrap-content>*{
    min-width: 90%;
  }
  .profil-display { display: flex ;}
  .profil-display-inv { display: none ;}
}
@media (max-width: 600px) {
  .gap-resize{ gap: 2px; }
  .width-resize{ width: 80%; }
  .cond-rendering-inv .padding-change{ padding: 10px 10px; }
  .height-resize-sup{height: 50px;}
  .band img {height: 60px }
  .imgbg{ width: 60%}
  .profilelight {
    width: 70px; 
    height: 70px;
    border-radius: 70px;
  }
  .height-resize-adm{ height: 60px; padding: 0 75px; }
}
@media (max-width: 500px) {
  .none { display: none; }
}
@media (max-width: 400px) {
  .band img {height: 50px }
  .profilelight {
    width: 50px; 
    height: 50px;
    border-radius: 50px;
  }
}
@media (max-width: 300px) {
  .width-resize{ width: 95%; }
  .logo { height: 60%; }

  .band img {height: 30px }
  .profilelight {
    width: 30px; 
    height: 30px;
    border-radius: 30px;
  }
  .width-resize{ width: 95%; }
  .supervisor-card{ width: 150px; height: 250px;}
  .admin-card{ width: 200px; height: 300px; }
  .height-resize-sup{height: 35px;}
  .profileheader {
    width: 40px;
    height: 42px;
    border-radius: 25px
  }
}